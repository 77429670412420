import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./components/navbar";
import { ROUTES } from "./utils/constants";
import isLoggedIn from "./utils/functions";

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const _isLoggedIn = isLoggedIn();
    if (!_isLoggedIn) {
      setLoggedIn(false);
      setIsLoading(false);
      localStorage.clear();
      return;
    }
    setLoggedIn(true);
    setIsLoading(false);
  }, []);

  if (isLoading) return <div>Loading ...</div>;

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer
          position="bottom-left"
          pauseOnFocusLoss={false}
        />
        <Routes>
          <Route
            path="/"
            element={
              loggedIn ? (
                <Navigate to="/transcripts" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {ROUTES.map(({ path, component: Component, showNavbar }, index) => {
            return (
              <Route
                key={index}
                path={path}
                element={
                  <div className="flex justify-center flex-col justify-center">
                    {loggedIn && showNavbar && <Navbar path={path} />}
                    <Component />
                  </div>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
