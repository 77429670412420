import React from 'react';

type SpinnerProps = {
    size?: 'small' | 'medium' | 'large';
    borderWidth?: 'thin' | 'normal' | 'thick';
    variation?: 'primary' | 'light';
    className?: string;
};

const SIZES = {
    small: 'h-4 w-4',
    medium: 'h-8 w-8',
    large: 'h-16 w-16'
}

const BORDER_WIDTH = {
    thin: 'border-2',
    normal: 'border-4',
    thick: 'border-8'
}

const BORDER_COLORS = {
    primary: 'border-r-minutes_accent border-minutes_accent/50',
    light: 'border-r-white border-white/50'
}

function Spinner({size = 'medium', borderWidth = 'normal', variation = "primary", className = ""}: SpinnerProps) {
    return (
        <div
            className={`inline-block animate-spin rounded-full bg-transparent border-solid border-4 ${SIZES[size]} ${BORDER_COLORS[variation]} ${BORDER_WIDTH[borderWidth]} ${className}`}></div>
    );
}

export default Spinner;