import axios, {AxiosError} from "axios";
import {Transcript, WhisperPrompt} from "../types/types";
import {toast} from "react-toastify";
import {handleApiError} from "./utils";

export const getTranscripts = async () => {
  const token = localStorage.getItem("access");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/transcriptions/`, config)
    return res.data
  } catch(err) {
    handleApiError(err as AxiosError)
  }
};

export const transcribeFile = async (
  audioFile: File,
  updateTranscript: (object: Transcript) => void,
  setProgress: (num: number) => void,
  setTimeStampTranscript: (object: any) => void,
  setTranscriptionLoading: (loading: boolean) => void,
  whisperPrompt?: WhisperPrompt,
): Promise<any> => {
  setTranscriptionLoading(true)
  const token = localStorage.getItem("access");

  const reader = new FileReader();

  reader.readAsDataURL(audioFile);

  reader.onload = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const body = new FormData();
    body.append(`audio`, audioFile, audioFile.name);

    if(whisperPrompt) {
      body.append("whisper_prompt.name", whisperPrompt.name)
      body.append("whisper_prompt.prompt", whisperPrompt.prompt)
      body.append("whisper_prompt.from_user", whisperPrompt.fromUser.toString())
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/transcribe/`,
      {
        method: "POST",
        headers: headers,
        body: body,
      }
    )
      .then(async (response) => {
        if(!response.ok) {
          const errorMessage = await response.json()
          throw Error(errorMessage)
        }

        const reader = response?.body?.getReader();

        // Read the response stream as text
        const decoder = new TextDecoder();
        let result = "";

        let transcript: Transcript | undefined;
        return reader?.read().then(function processResult(resultObj): any {
          if (resultObj.done) {
            setTranscriptionLoading(false)
            return;
          }
          const chunk = decoder.decode(resultObj.value, { stream: true });

          const json = JSON.parse(chunk);

          if (
            json?.["text"] !== undefined &&
            json?.["progress"] !== undefined &&
            transcript !== undefined
          ) {
            result = `${result} ${json["text"]}`;
            transcript.text = result;
            updateTranscript(transcript);
            setTimeStampTranscript((prevItems: any[]) => [...prevItems, json]);
            setProgress(Number(json["progress"]));
          } else if (json?.["object"] !== undefined) {
            transcript = json["object"];
            updateTranscript(json["object"]);
          }
          setTranscriptionLoading(false)
          return reader.read().then(processResult);
        });
      })
      .catch((error) => {
        toast.error(error.message)
        setTranscriptionLoading(false)
      });

    return response;
  };
};
