const Tabs = [
  {
    name: "Transcripts",
    path: "/transcripts",
  },
  // {
  //   name: "Agendas",
  //   path: "/agendas",
  // },
];

export default Tabs;
