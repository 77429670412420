import {FormEvent, useState} from "react";

const useForm = (submit: () => void, fieldsNumber: number) => {
  //Form values
  const [values, setValues] = useState<Record<string, string>>({});
  //Errors
  const [errors, setErrors] = useState<Record<string, string>>({});

  const regexEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const deleteError = (myKey: string) => {
    Object.keys(errors).forEach(function (key: string) {
      if (key === myKey) {
        delete errors[key];
      }
    });
  };

  //A function to validate each input values
  const validate = (_: any, name: string, value: string) => {
    let errorMessage = "";
    if (value === "") errorMessage = `Enter your ${name}!`;
    else if (name === "email" && !regexEmail.test(value)) {
      errorMessage = "Email is not valid!";
    } else if (
      fieldsNumber > 2 &&
      ((name === "repeat_password" && value !== values["password"]) ||
        (name === "password" && value !== values["repeat_password"]))
    ) {
      errorMessage = "Passwords not matching!";
    }

    if (errorMessage === "" && value !== "") {
      if (
        (name === "repeat_password" || name === "password") &&
        fieldsNumber > 2
      ) {
        deleteError("repeat_password");
        deleteError("password");
      } else {
        deleteError(name);
      }
    } else setErrors({ ...errors, [name]: errorMessage });
  };

  //A method to handle form inputs
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    //To stop default events
    event.persist();

    let name = (event.target as HTMLInputElement).name;
    let val = (event.target as HTMLInputElement).value;

    validate(event, name, val);

    setValues({
      ...values,
      [name]: val,
    });
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    if (event) event.preventDefault();
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(values).length !== 0 &&
      Object.keys(values).length === fieldsNumber
    ) {
      submit();
    } else {
      alert("There is an Error!");
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useForm;
