import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/authentication";
import useForm from "../form/useForm";
import Input from "../Input";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const submit = async () => {
    const res = await login(email, password);
    if (res) {
      localStorage.setItem("access", res["access"]);
      localStorage.setItem("refresh", res["refresh"]);
      window.location.href = "/";
    } else {
      alert(t("try_again"));
    }
  };

  const { handleChange, values, errors, handleSubmit } = useForm(submit, 2);

  useEffect(() => {
    Object.keys(values).forEach(function (key: string) {
      if (key === "email") {
        setEmail(values[key]);
      } else if (key === "password") {
        setPassword(values[key]);
      }
    });
  }, [values, errors]);

  const errorLabel = (error: string | null) =>
    error ? (
      <label className="block text-size14 font-medium text-red-600 mt-1">
        {error}
      </label>
    ) : (
      <></>
    );

  return (
    <div>
      <div className={`flex flex-col gap-[13px]`}>
        <div>
          <Input
            value={email}
            label={t("email")}
            name="email"
            onChange={handleChange}
            placeholder={t("email")}
            type="email"
            hasBorder
          />
          {errorLabel(errors["email"])}
        </div>
        <div>
          <Input
            value={password}
            label={t("password")}
            name="password"
            onChange={handleChange}
            placeholder={t("password")}
            type="password"
            hasBorder
          />
          {errorLabel(errors["password"])}
        </div>
        <button
          onClick={handleSubmit}
          className="w-[200px] mt-[14px] bg-gray-600 rounded-xl text-white self-center h-[36px]"
        >
          {t("login")}
        </button>

        <div className="mt-[14px] flex flex-row large gap-[8px]">
          <div className="h-[22px]">{t("dont_have_account")}</div>

          <button onClick={() => navigate("/register")}>
            {t("create_one")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
