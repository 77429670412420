import axios, {AxiosError} from "axios";
import { MeetingMinutes } from "../types/types";
import {handleApiError} from "./utils";

export const generateMeetingMinutes = async (id: number): Promise<string> => {
  const token = localStorage.getItem("access");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const body = {
    transcription_id: id,
  };
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/summary/`, body, config)
    return res.data.result
  } catch(err) {
    return handleApiError(err as AxiosError)
  }
};

export const getMeetingMinutes = async (
  transcriptionId: number
): Promise<MeetingMinutes[]> => {
  const token = localStorage.getItem("access");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}api/transcriptions/${transcriptionId}/minutes`,
          config
      )
    return res.data
  } catch(err) {
    return handleApiError(err as AxiosError)
  }
};
