import {ChangeEvent, useEffect, useState} from "react";
import {toast} from "react-toastify";
import ArrowRight from "../icons/ArrowRight";
import Spinner from "../Spinner";

interface Props {
    loading: boolean,
    sendPromptHandler: (prompt: string) => void
}

const CustomPromptInput = ({loading, sendPromptHandler}: Props) => {
    const [prompt, setPrompt] = useState<string>('')

    const handleCustomPromptInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPrompt(e.target.value)
    }

    const submitCustomPromptInput =  () => {
        sendPromptHandler(prompt)
        setPrompt('')
    }

    return(
        <div className="flex w-full px-6 py-6 flex-col justify-between">
            <div className="mb-2">{loading ? (
                <div className="flex flex-row items-center">
                    <p className="mr-2">AI-assistant is writing response</p>
                    <Spinner size="small"/>
                </div>
                ) : ""}</div>
            <div>
                <input onChange={handleCustomPromptInputChange} value={prompt} className="p-3 w-4/5 rounded-l-2xl border-t-2 border-b-2 border-l-2" type="text" placeholder="Type what you want to do"/>
                <button onClick={submitCustomPromptInput} className="p-3 w-1/5 border-2 rounded-r-2xl text-minutes_accent font-bold" type="button" disabled={!prompt || loading}>Send <ArrowRight className="ml-0.5 mb-1"/></button>
            </div>
        </div>
    )
}

export default CustomPromptInput;