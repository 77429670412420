import {FormEvent} from "react";

interface Props {
  value: string;
  placeholder: string;
  type?: string;
  name: string;
  label: string;
  hasBorder?: boolean;
  image?: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  className?: string;
}

const Input = ({
  value,
  placeholder,
  type="text",
  name,
  label,
  hasBorder,
  image,
  onChange,
  className=""
}: Props) => {
  return (
    <div className={`flex flex-col w-full content-start ${className}`}>
      <div className="text-size14 font-bold mb-[4px]">{label}</div>
      <div className={image ? "relative" : ""}>
        {image && (
          <span className="prefix-icon absolute top-[13px] left-[13px]">
            <img
              src={`${process.env.PUBLIC_URL}/${image}`}
              alt="Icon"
              width={16}
              height={16}
            />
          </span>
        )}
        <input
          name={name}
          type={type}
          onChange={onChange}
          className={`text-size15 rounded-[2px] py-[8px] 
            pr-[13px] placeholder-gray-400 w-full
            focus-visible:outline-[1px]
            ${hasBorder ? "border border-gray-400" : ""} 
            ${image ? "pl-[36px]" : "pl-[13px]"} 
          `}
          placeholder={placeholder}
          value={value}
          required
        />
      </div>
    </div>
  );
};

export default Input;
