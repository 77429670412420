import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Tabs from "./tabs";

interface Props {
  path: string;
}

const Navbar = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="flex flex-row justify-between border-minutes_separator border-b-[1px] px-[41px] bg-minutes_bg">
      <div className="flex flex-row gap-[33px]">
        {Tabs.map(({ name, path }, index) => {
          return (
            <button
              key={index}
              onClick={() => navigate(path)}
              className={`text-size28 ${
                props.path === path
                  ? "border-minutes_text text-minutes_text border-b-2 py-[19px] font-medium"
                  : "text-minutes_text my-[19px] font-normal"
              }`}
            >
              {name}
            </button>
          );
        })}
      </div>
      <button className="text-size18" onClick={logout}>
        {t("logout")}
      </button>
    </div>
  );
};

export default Navbar;
