import {toast} from "react-toastify";
import {AxiosError} from "axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {getTranscripts} from "../../api/transcripts";
import {Transcript} from "../../types/types";
import isLoggedIn from "../../utils/functions";
import "./styles.css";
import {SUPPORTED_FILE_FORMATS} from "../../utils/constants";
import FileInput from "../../components/FileInput";
import audioWave from "../../assets/images/audio-wave.svg";
import Spinner from "../../components/Spinner";

const TranscriptScreen = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [file, setFile] = useState<File>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState("")
    const [transcripts, setTranscripts] = useState<Transcript[]>([]);

    const fetch = async () => {
        setIsLoading(true)
        setError("")
        try {
            const transcripts = await getTranscripts();
            if (transcripts === undefined) {
                localStorage.clear();
                alert("Please login again");
                navigate("/login");
                return;
            }
            setTranscripts(transcripts);
        } catch(err) {
            const errorMessage = (err as AxiosError).message
            toast.error(errorMessage)
            setError(errorMessage)
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = (file: File) => {
        setFile(file)
    }

    useEffect(() => {
        const _isLoggedIn = isLoggedIn();
        if (!_isLoggedIn) {
            alert("Please Login!");
            navigate("/");
            return;
        }
        fetch();
    }, [navigate]);


    return (
        <div className="px-[41px] pt-[56px] bg-minutes_bg">
            <div className="grid grid-cols-2 gap-[40px] relative items-start">
                <div className="flex flex-col gap-[15px] transcripts-list">
                    {error ? <p className="text-red-500 mx-auto">{error}</p> :
                        !isLoading ? transcripts.length > 0 ?
                            transcripts.map(({id, text, user, created_at, audio}, index) => {
                            const date = new Date(created_at);
                            const options: Intl.DateTimeFormatOptions = {
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: false,
                            };
                            const formattedDate = date.toLocaleString("en-US", options);
                            return (
                                <Link key={index} to={`/minutes/${id}`}>
                                    <div
                                        className="rounded-[20px] bg-white p-[20px] flex flex-row flex-[5]"
                                    >
                                        <div className="flex-[4] text-size14 line-clamp-4">
                                            <div className="flex justify-between">
                                                <div className="font-bold mb-3">
                                                    {audio
                                                        .split("/")
                                                        .pop()
                                                        ?.replace(/_.*(?=\.)/, "")}
                                                </div>
                                                <div className="flex-[1] flex justify-end text-size14 text-minutes_text/[.5]">
                                                    {formattedDate}
                                                </div>
                                            </div>
                                            <div>
                                                {text.slice(0, 300).split(" ").slice(0, -1).join(" ")}...
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })
                    : <p className="mx-auto mt-10">No transcripts yet</p>
                    : <Spinner className="m-auto"/>}
                </div>

                <div className="w-full h-full max-h-[620px] max-w-[558px] mx-auto relative">
                    <div className="w-full h-[130px] rounded-[20px] absolute -bottom-1 bg-gradient-to-t from-minutes_bg"></div>
                    <div className="file-upload-container h-full flex flex-col items-center pb-[120px] rounded-[20px]">
                        <img
                            src={audioWave}
                            className="min-h-[158px] max-h-[605px] mt-[120px] mb-6"
                            alt="audio-wave"
                        />
                        <div className="text-center mb-12 mx-3">
                            <p>{t("drag & drop file")}</p>
                            <p>{t("supported file formats")}</p>
                        </div>

                        {file ? (
                            <div className="flex flex-row items-center gap-[10px]">
                                <button
                                    className="bg-minutes_text text-white p-[10px] rounded-[4px] w-[75px] text-size14"
                                    onClick={() => setFile(undefined)}
                                >
                                    {t("remove")}
                                </button>
                                <div>{file.name}</div>
                            </div>
                        ) : (
                            <FileInput
                                label={t('choose file')}
                                accept={SUPPORTED_FILE_FORMATS.join(',')}
                                onFileSelect={handleFileSelect}
                            />
                        )}
                        {file && (
                            <button
                                className="bg-minutes_text text-white p-[10px] rounded-[4px] w-[75px] text-size14"
                                onClick={() => navigate("/minutes", {state: {file: file}})}
                            >
                                {t("process")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TranscriptScreen;
