import {AxiosError} from "axios";
import {CustomRequestResponse, TranscriptionId} from "../types/types";
import {useEffect, useState} from "react";
import {getCustomRequestsByTranscriptionId, sendCustomPromptRequest} from "../api/customPromptRequest";
import {toast} from "react-toastify";


const useCustomRequest = (transcriptionId: TranscriptionId) => {
    const [promptResponses, setPromptResponses] = useState<CustomRequestResponse[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        if (transcriptionId) {
            (async () => {
                try {
                    const res = await getCustomRequestsByTranscriptionId(transcriptionId)
                    const {data} = res

                    setPromptResponses(data)
                } catch(err) {
                    toast.error((err as AxiosError).message)
                }
            })()
        }
    }, [transcriptionId])

    const addNewPromptResponse = async (prompt: string) => {
        setLoading(true)
        try {
            const res = await sendCustomPromptRequest(transcriptionId, prompt)
            const {data} = res
            const newPromptResponse: CustomRequestResponse = {
                id: data.id,
                transcription: transcriptionId,
                prompt,
                response: data.result
            }
            setPromptResponses([...promptResponses, newPromptResponse])
            setError('')
        } catch(err) {
            const errorMessage = (err as AxiosError).message
            toast.error(errorMessage)
            setError(errorMessage)
        }

        setLoading(false)
    }

    return {
        loading,
        promptResponses,
        addNewPromptResponse
    }
}

export default useCustomRequest