import AgendaScreen from "../screens/Agendas";
import LoginScreen from "../screens/Login";
import MinutesScreen from "../screens/Minutes";
import TranscriptScreen from "../screens/Transcripts";
import { Navlink } from "../types/types";

export const ROUTES: Navlink[] = [
  {
    name: "login",
    path: "/login",
    showNavbar: false,
    component: LoginScreen,
  },
  {
    name: "transcripts",
    path: "/transcripts",
    showNavbar: true,
    component: TranscriptScreen,
  },
  {
    name: "minutes",
    path: "/minutes/:id?",
    showNavbar: false,
    component: MinutesScreen,
  },
  // {
  //   name: "agendas",
  //   path: "/agendas",
  //   showNavbar: true,
  //   component: AgendaScreen,
  // },
];

export const SUPPORTED_FILE_FORMATS = [
    ".mp3",
    ".mp4a",
    ".wav",
    ".mpga"
]