import React from 'react';

interface ArrowLeftProps {
  size?: number;
  color?: string;
  className?: string;
  [key: string]: any;
}

const ArrowRight = ({ size = 14, color = 'currentColor', className, ...rest }: ArrowLeftProps) => {
  return (
    <svg className={`inline-block ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={color} width={size} height={size} {...rest}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
    </svg>
  );
};

export default ArrowRight;