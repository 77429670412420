import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/LoginForm";

const LoginScreen = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) alert("test");
    else setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <div>{t("loading")} ...</div>;

  return (
    <div className="flex justify-center h-screen">
      <div
        className={`flex flex-col w-full justify-center px-4`}
        style={{ maxWidth: 450, width: "100%" }}
      >
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginScreen;
