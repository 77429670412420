import Select from "react-select";
import {useTranslation} from "react-i18next";
import GPT3Tokenizer from 'gpt3-tokenizer'
import Input from "../Input";
import Spinner from "../Spinner";
import {FormEvent, useEffect, useState} from "react";
import TextArea from "../Input/TextArea";
import {getWhisperPrompts} from "../../api/whisperPrompts";
import {Transcript, WhisperPrompt} from "../../types/types";
import {transcribeFile} from "../../api/transcripts";
import {MAX_WHISPER_PROMPT_TOKENS} from "../../constants";
import {toast} from "react-toastify";
import {AxiosError} from "axios";

interface Props {
    transcriptionLoading: boolean;
    file: File;
    setTranscript: (transcript: Transcript) => void;
    setProgress: (progress: number) => void;
    setTimeStampTranscript: (object: any) => void,
    setTranscriptionLoading: (loading: boolean) => void,
}

const BeforeTranscriptionContent = ({
    transcriptionLoading,
    file,
    setTranscript,
    setProgress,
    setTimeStampTranscript,
    setTranscriptionLoading
}: Props) => {
    const {t} = useTranslation();
    const [whisperPrompts, setWhisperPrompts] = useState<{ value: string, label: string }[]>([])
    const [promptName, setPromptName] = useState('')
    const [prompt, setPrompt] = useState('')
    const [tokens, setTokens] = useState(0)
    const [fromUser, setFromUser] = useState(true)

    useEffect(() => {
        const tokenizer = new GPT3Tokenizer({type: 'gpt3'})
        const encoded: { bpe: number[]; text: string[] } = tokenizer.encode(prompt);
        setTokens(encoded.text.length)
    }, [prompt])

    const handleInputChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        setter: (val: string) => void) => {

        if (!fromUser) {
            setFromUser(true)
        }
        setter((event.target as HTMLInputElement).value)
    }

    const formatWhisperPrompts = (prompts: WhisperPrompt[]) => {
        return prompts.map(whisperPrompt => {
            return {
                label: whisperPrompt.name,
                value: whisperPrompt.prompt
            }
        })
    }

    const isPromptValid = () => {
        return tokens <= MAX_WHISPER_PROMPT_TOKENS
    }

    const isTranscribeButtonDisabled = () => {
        return transcriptionLoading || !isPromptValid()
    }

    useEffect(() => {
        (async () => {
            try {
                const res = await getWhisperPrompts()
                const {data} = res
                setWhisperPrompts(formatWhisperPrompts(data))
            } catch (err) {
                toast.error((err as AxiosError).message)
            }
        })()
    }, [])

    const updateTranscript = (object: Transcript) => {
        const temp_object: Transcript = {
            id: object.id,
            audio: object.audio,
            created_at: object.created_at,
            text: object.text,
            user: object.user,
        };

        setTranscript(temp_object);
    };


    const transcribe = () => {
        let whisperPrompt = undefined
        if (prompt) {
            whisperPrompt = {
                name: promptName,
                prompt,
                fromUser
            }
        }
        transcribeFile(
            file,
            updateTranscript,
            setProgress,
            setTimeStampTranscript,
            setTranscriptionLoading,
            whisperPrompt
        );
    };

    return (
        <>
            <div className="flex-1 flex flex-col pt-10 w-full px-[50px]">
                <h4 className="font-bold text-lg">Context Prompts</h4>
                <Select
                    className="min-w-[150px] mb-5"
                    options={whisperPrompts}
                    onChange={(newValue) => {
                        if (newValue) {
                            setPromptName(newValue.label)
                            setPrompt(newValue.value)
                            setFromUser(false)
                        }
                    }}
                />
                <Input
                    value={promptName}
                    label="Prompt Name"
                    name="promptName"
                    onChange={(e) => handleInputChange(e, setPromptName)}
                    placeholder="Name of the prompt"
                    hasBorder
                    className="mb-5"
                />
                <div className="mb-5">
                    <TextArea
                        label="Prompt"
                        placeholder={`Add prompt to improve the quality of the transcripts.\n\n-To specify words, names, and acronyms: "The transcript is about SaaS, UX, ChatGPT, and IBM."\n\nFor more info on how to write prompts visit OpenAI documentation: https://platform.openai.com/docs/guides/speech-to-text/prompting
                        `}
                        onChange={(e) => handleInputChange(e, setPrompt)}
                        value={prompt}
                        className="w-full"
                        hasBorder
                    />
                    <p className='text-black/50'>
                        <span
                            className={`${isPromptValid() ? 'text-black/50' : 'text-red-500'}`}>{tokens}</span>/{MAX_WHISPER_PROMPT_TOKENS} tokens
                    </p>
                </div>
                <button
                    className={`flex items-center justify-center ${isTranscribeButtonDisabled() ? 'bg-minutes_accent/50' : 'bg-minutes_accent'} text-white px-[10px] py-[5px] rounded-[4px] text-size14`}
                    disabled={isTranscribeButtonDisabled()}
                    onClick={transcribe}
                >
                    {transcriptionLoading ? t("initialization") : t("transcribe")}
                    {transcriptionLoading &&
                        <Spinner size="small" variation="light" className="ml-2"/>}
                </button>
            </div>
        </>
    )
}

export default BeforeTranscriptionContent