import {toast} from "react-toastify";
import {AxiosError} from "axios";
import {Fragment, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Transcript, User} from "../../types/types";
import {getTranscripts} from "../../api/transcripts";
import {generateMeetingMinutes, getMeetingMinutes} from "../../api/minutes";
import {getUserData} from "../../api/authentication";
import "./styles.css";
import {useTranslation} from "react-i18next";
import CustomPromptInput from "../../components/CustomPromptInput/CustomPromptInput";
import useCustomRequest from "../../hooks/useCustomRequest";
import Chevron from "../../components/icons/Chevron";
import Spinner from "../../components/Spinner";
import SkeletonLoader from "../../components/SkeletonLoader";
import BeforeTranscriptionContent from "../../components/MinutesScreen/BeforeTranscriptionContent";

enum ScreenState {
    Idle,
    Transcribing,
    GeneratingMinutes,
    EditMode,
}

const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const remainingSeconds = totalSeconds - hours * 3600 - minutes * 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const MinutesScreen = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {state} = useLocation();
    const {file} = state || {};
    const [user, setUser] = useState<User>();
    const [screenState, setScreenState] = useState<ScreenState>(ScreenState.Idle);
    const [transcript, setTranscript] = useState<Transcript>();
    const [minutes, setMinutes] = useState<string>("");
    const [progress, setProgress] = useState<number>(0);
    const [timeStampTranscript, setTimeStampTranscript] = useState<any[]>([]);
    const [transcriptionLoading, setTranscriptionLoading] = useState<boolean>(false)
    const [meetingSummaryLoading, setMeetingSummaryLoading] = useState<boolean>(false)
    const {id} = useParams();

    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const parseId = parseInt(id)
                try {
                    const res = await getMeetingMinutes(parseId);
                    const transcripts = await getTranscripts();
                    const transcriptDetails = transcripts.find(
                        (item: Transcript) => item.id === parseId
                    );
                    if (res && res.length > 0 && res[0]?.minutes) {
                        setMinutes(res[0]?.minutes);
                    }

                    if (transcriptDetails) {
                        setTranscript(transcriptDetails);
                        setTimeStampTranscript(JSON.parse(transcriptDetails.timestamps));
                    } else {
                        navigate("/transcripts");
                    }

                    setProgress(100);
                } catch(err) {
                    toast.error((err as AxiosError).message)
                }
            } else if (state?.file === undefined) {
                navigate("/transcripts");
            }
        };
        fetch();
    }, [state, navigate]);

    useEffect(() => {
        const fetch = async () => {
            const res = await getUserData();
            setUser(res);
        };
        if (user === undefined) fetch();
    }, [user]);

    const {
        loading,
        promptResponses,
        addNewPromptResponse
    } = useCustomRequest(transcript?.id)

    useEffect(() => {
        const fetch = async () => {
            if (transcript) {
                setMeetingSummaryLoading(true)
                try {
                    const res = await generateMeetingMinutes(transcript.id);
                    setMinutes(res);

                }catch(err) {
                    toast.error((err as AxiosError).message)
                }
                setMeetingSummaryLoading(false)
            }
        };
        if (progress === 100 && minutes === "") {
            fetch();
        }
    }, [minutes, progress, transcript]);

    useEffect(() => {
        if (minutes !== "") {
            setScreenState(ScreenState.EditMode);
        } else if (progress === 100) {
            setScreenState(ScreenState.GeneratingMinutes);
        } else if (transcript !== undefined) {
            setScreenState(ScreenState.Transcribing);
        } else {
            setScreenState(ScreenState.Idle);
        }
    }, [transcript, minutes, progress]);

    const generateLeftSectionContent = () => {
        if (minutes) {
            return (
                <div className="p-8 overflow-y-scroll">
                    {/*MEETING SUMMARY*/}
                    <div className="mb-6 whitespace-break-spaces">
                        {minutes}
                    </div>
                    {/*CHAT CONVO*/}
                    <div>
                        {promptResponses.map(text => (
                            <Fragment key={text.id}>
                                <div className="flex justify-end">
                                    <p className="bg-minutes_accent text-minutes_surface p-2 rounded-lg w-fit mb-6">{text.prompt}</p>
                                </div>
                                <div className="flex">
                                    <p className="text-minutes_subtext mb-6">{text.response}</p>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="h-full flex flex-col items-center justify-center">
                    {screenState === ScreenState.Transcribing ?
                        (
                            // Percentage during transcription
                            <div className="flex flex-col items-center text-size14 text-minutes_accent">
                                <div className="flex items-center">
                                    {`${progress}%`}
                                    <Spinner size="small" className="ml-1"/>
                                </div>
                                <p>{t("processing")}</p>
                            </div>
                        )
                        : id ?
                            (
                                // Loader before showing meeting summary
                                <>
                                    <Spinner/>
                                    <p className="mt-3">
                                        Loading meeting summary and conversations...
                                    </p>
                                </>
                            )
                            : screenState === ScreenState.GeneratingMinutes ?
                                (
                                    // Generating meeting summary
                                    <>
                                        <Spinner/>
                                        <p className="mt-3">
                                            {t("analysing")}
                                        </p>
                                    </>
                                )
                                : <BeforeTranscriptionContent
                                    transcriptionLoading={transcriptionLoading}
                                    file={file}
                                    setTranscript={setTranscript}
                                    setProgress={setProgress}
                                    setTimeStampTranscript={setTimeStampTranscript}
                                    setTranscriptionLoading={setTranscriptionLoading}
                                />
                    }
                </div>
            )
        }
    }

    return (
        <div className="flex flex-col h-screen">
            {/*TOPBAR*/}
            <div className="flex items-center border-b-[1px] py-2 h-[50px]">
                <div className="flex flex-row gap-[5px] items-center">
                    <button
                        className="flex items-center justify-center rounded-full h-[38px] w-[38px]"
                        onClick={() => navigate("/transcripts")}
                    >
                        <Chevron size={20}/>
                    </button>
                    <div className="flex text-size18 text-minutes_text font-medium">
                        {/* TODO: Replace with summary title for finished transcription*/}
                        {file ? file?.name : "Meeting Summary"}
                    </div>
                </div>
            </div>
            {/*MAIN SCREEN*/}
            <div className="relative flex h-[calc(100%-50px)] overflow-hidden">
                {/*LEFT SECTION*/}
                <div className="flex-1 flex flex-col bg-white border-r-2">
                    {generateLeftSectionContent()}
                    {/*MESSAGE INPUT*/}
                    <CustomPromptInput
                        loading={loading}
                        sendPromptHandler={addNewPromptResponse}
                    />
                </div>
                {/*RIGHT SECTION*/}
                <div className="flex-1 bg-minutes_bg px-8 pt-10 pb-8 overflow-y-scroll">
                    <div className="">
                        {timeStampTranscript.map((obj: any) => (
                            <div key={obj.start_time} className="flex mx-[16px] my-[16px]">
                                <div className="flex">
                                    <p className="font-bold mr-4 text-minutes_subtext/40 text-sm">
                                        {formatTime(obj.start_time)}:
                                    </p>
                                    <p className="text-minutes_text text-size15">{obj.text}</p>
                                </div>
                            </div>
                        ))}
                        {screenState !== ScreenState.Idle && progress < 100 ? (
                            <SkeletonLoader/>
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MinutesScreen;
