import {HTMLProps} from "react";

interface Props {
    label: string;
    hasBorder?: boolean;
}

type TextAreaProps = Props & HTMLProps<HTMLTextAreaElement>
const TextArea = ({label, value, hasBorder = true, rows = 5, className, ...props}: TextAreaProps) => {
    return (
        <>
            <div className="text-size14 font-bold mb-[4px]">{label}</div>
            <textarea
                className={`
                px-3 py-2   
                ${hasBorder ? "border border-gray-400 " : ""} 
                ${className}
            `}
                rows={rows}
                value={value}
                {...props}
            >
        </textarea>
        </>
    )
}

export default TextArea