import React from 'react';

interface ChevronProps {
  size?: number;
  color?: string;
  className?: string;
  [key: string]: any;
}

const Chevron = ({ size = 14, color = 'currentColor', className, ...rest }: ChevronProps) => {
  return (
    <svg className={`inline-block ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={color} width={size} height={size} {...rest}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  );
};

export default Chevron;