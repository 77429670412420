import {AxiosError} from "axios";

const getAccessTokenFromLocalStorage = () => {
    return localStorage.getItem("access");
}

export const getRequestConfig = () => {
    return {
        headers: {Authorization: `Bearer ${getAccessTokenFromLocalStorage()}`},
    }
}

export const handleApiError = (error: AxiosError) => {
    const {response} = error
    let errorMessage = 'Server error. Try again later.'

    if(response) {
        const {data} = response
        errorMessage = data as string
    }

    throw Error(errorMessage) as AxiosError
}
