import {useTranslation} from "react-i18next";
import {SUPPORTED_FILE_FORMATS} from "../utils/constants";
import {ChangeEvent} from "react";
import {toast} from "react-toastify";

interface Props {
    label: string;
    accept: string;
    onFileSelect: (file: File) => void;
}

const FileInput = ({label, accept, onFileSelect}: Props) => {
    const {t} = useTranslation();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const fileExtension = `.${file.name.split('.').pop()}`

            if (!SUPPORTED_FILE_FORMATS.includes(fileExtension)) {
                toast.error(t('file format error'))
            } else {
                onFileSelect(file);
            }
        }
    }

    return (
        <label className=" bg-minutes_text text-white p-[10px] rounded-[4px] w-[95px] text-size14 cursor-pointer">
            <input type="file" onChange={handleFileChange} accept={accept}/>
            {label}
        </label>
    )
}

export default FileInput