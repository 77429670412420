import axios from "axios";

export const login = async (email: string, password: string) => {
  const body = {
    email: email,
    password: password,
  };

  const res = await axios
    .post(`${process.env.REACT_APP_API_URL}api/login/`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
  return res;
};

export const getUserData = async () => {
  const token = localStorage.getItem("access");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const res = await axios
    .get(`${process.env.REACT_APP_API_URL}api/user/`, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
  return res;
};
