import axios, {AxiosError} from "axios";
import {TranscriptionId} from "../types/types";
import {getRequestConfig, handleApiError} from "./utils";

export const sendCustomPromptRequest = async (transcriptionId: TranscriptionId, prompt: string) => {
    const config = getRequestConfig()
    const body = {
        transcription_id: transcriptionId,
        prompt,
    }

    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}api/custom-request/`, body, config)
    } catch(err) {
        return handleApiError(err as AxiosError)
    }
}

export const getCustomRequestsByTranscriptionId = async (transcriptionId: TranscriptionId) => {
    const config = getRequestConfig()

    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}api/transcriptions/${transcriptionId}/custom-request/`, config)
    } catch(err) {
        return handleApiError(err as AxiosError)
    }
}
